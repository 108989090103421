<template>
  <CRow>
    <CCol col="12">
      <CCard v-if="itemData">
        <CCardHeader> المستخدم رقم: {{ $route.params.id }} </CCardHeader>
        <CCardBody>
          <CAlert color="info">
            <CRow>
              <CCol col="3">الاسم</CCol>
              <CCol col="3"> {{ itemData.name }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3">الموبايل</CCol>
              <CCol col="3"> {{ itemData.phone }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3"> الايميل </CCol>
              <CCol col="3"> {{ itemData.email }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3"> صورة الهوية </CCol>
              <CCol col="3">
                <CImg height="250px" width="250px" :src="itemData.verification_image_url" />
              </CCol>
            </CRow>
          </CAlert>


          <CAlert color="info">
            <CRow>
              <CCol col="3"> صورة المحامي </CCol>
              <CCol col="3">
                <CImg height="250px" width="250px" :src="itemData.personal_image_url" />
              </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3"> سعر الاستشارة </CCol>
              <CCol col="3"> {{ itemData.consultation_fee }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3"> سنوات الخبرة </CCol>
              <CCol col="3"> {{ itemData.years_of_experience }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3"> سعر الحجز </CCol>
              <CCol col="3"> {{ itemData.price_per_hour }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3"> العمر </CCol>
              <CCol col="3"> {{ itemData.age }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3"> نبذة مختصرة </CCol>
              <CCol col="3"> {{ itemData.short_bio }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3"> نبذة كامله </CCol>
              <CCol col="3"> {{ itemData.long_bio }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3"> الجوائز </CCol>
              <CCol col="3"> {{ itemData.awards }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3"> حالة التسجيل </CCol>
              <CCol col="3">
                <b :class="`text-${getBadge(itemData.register_status)}`">
                  {{ itemData.register_status == "1" ? "مكتمل" : "غير مكتمل" }}
                </b>
              </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3"> الحالة </CCol>
              <CCol col="3">
                <b :class="`text-${getBadge(itemData.status)}`">
                  {{ itemData.status == "1" ? "نشط" : "متوقف" }}
                </b>
              </CCol>
            </CRow>
          </CAlert>
          <CAlert color="info" v-if="itemData">
            <CRow>
              <CCol col="5">
                <div class="image-container">
                  <img ref="imageRef" :src="getImage(itemData)" crossorigin="anonymous">
                </div>
                <div class="btn-wrapper">
                  <!-- <CButton class="btn-action" color="info" @click="cropImage">crop</CButton> -->
                  <CButton class="btn-action" color="info" @click="rotate(90)">rotate 90</CButton>
                  <CButton class="btn-action" color="info" @click="rotate(-90)">rotate -90</CButton>
                  <CButton class="btn-action" color="success" @click="saveCropped">SAVEEEEE</CButton>
                </div>
              </CCol>
              <CCol col="2"></CCol>
              <CCol col="5">
                <p>Preview</p>
                <div class="preview">
                  <img :src="croppedImageUrl" crossorigin="anonymous">
                </div>
              </CCol>
              
            </CRow>
          </CAlert>

        </CCardBody>
        <CCardFooter>
          <CButton color="info" @click="goBack">رجوع</CButton>
        </CCardFooter>
      </CCard>
      <Spinner v-else />
    </CCol>
  </CRow>
</template>

<style>

.image-container img {
  display: block;

  /* This rule is very important, please don't ignore this */
  max-width: 100%;

}

.btn-wrapper {
  margin-top: 20px;
}

.btn-action {
  margin-left: 10px;
}

</style>

<script>
// import lawyersData from "./LawyersData";
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  name: "Lawyer",
  data() {
    return {
      itemData: null,
      lawyersOpened: null,
      cropper: null,
      croppedImageUrl: null
    };
  },
  created() {
    this.getLawyer();
  },
  methods: {
    getImage(itemData) {
      return process.env.VUE_APP_API_URL + '/lawyers/images/' + itemData.id;
    },
    getLawyer() {
      this.toggleLoadSpinner();

      this.$http
        .get("lawyers/" + this.$route.params.id)
        .then((res) => {
          // handle success
          this.itemData = res.data.data;
          this.toggleLoadSpinner();
          setTimeout(() => {
            this.initializeCropper();
          }, 2000);
        })
        .catch((error) => {
          //
          this.toggleLoadSpinner();
        });
    },
    toggleLoadSpinner() {
      this.$store.commit("toggleLoadSpinner");
    },
    goBack() {
      this.lawyersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/lawyers" });
    },
    getBadge(status) {
      switch (status) {
        case "Active":
          return "success";
        case "Inactive":
          return "secondary";
        case "Pending":
          return "warning";
        case "Banned":
          return "danger";
        default:
          "primary";
      }
    },

    initializeCropper() {
      const image = this.$refs.imageRef;
      this.cropper = new Cropper(image, {
        checkCrossOrigin: false,
        aspectRatio: NaN,
        viewMode: 2,
        preview: '.preview',
        background: false,
        minContainerWidth: 300,
        crop: () => {
          const canvas = this.cropper.getCroppedCanvas();
          this.croppedImageUrl = canvas.toDataURL('image/jpeg');
        },
      });
    },

    // saveCropped() {

    // },
    saveCropped() {
      if (!this.cropper) {
        return;
      }
      // Get the cropped image data
      const croppedCanvas = this.cropper.getCroppedCanvas();

      if (croppedCanvas) {

        if (croppedCanvas.toDataURL()) {
          this.toggleLoadSpinner();

          this.$http
            .put(`/lawyers/picture/${this.itemData.id}`, {
              'picture': croppedCanvas.toDataURL(),
            })
            .then((res) => {
              // handle success
              if (res.data.status == 200) {
                this.itemData = res.data.data
              }
            })
            .catch((error) => {
              // handle error
              if ((error.response.data.status == 422)) {
                this.formErrors = error.response.data.error.errors;
              }
            }).finally(() => {
              this.toggleLoadSpinner();
            });
        }
      }
    },

    rotate(degree) {
      if (!this.cropper) {
        return;
      }

      this.cropper.rotate(degree);

      const canvas = this.cropper.getCroppedCanvas();
      this.croppedImageUrl = canvas.toDataURL('image/jpeg');
    }
  },
};
</script>
